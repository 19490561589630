<template>
  <section class="profile">
    <div class="d-flex justify-content-between align-items-center">
      <h5 class="page-title">Профиль</h5>
      <div @click="logout()" class="logout cursor-pointer">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          fill="currentColor"
          class="bi bi-box-arrow-right"
          viewBox="0 0 16 16"
        >
          <path
            fill-rule="evenodd"
            d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z"
          />
          <path
            fill-rule="evenodd"
            d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z"
          />
        </svg>
        Выйти
      </div>
    </div>

    <b-row class="mt-4">
      <b-col sm="6" offset-sm="0">
        <div class="profile-box mb-3">
          <div class="profile-avatar">
           
          </div>
          <div class="profile-info">
            <div class="profile-info-title">
              {{ user.fullname }}
            </div>
            <div class="profile-info-email ">
              <template v-if="user.email">
                Email: <span class="font-weight-bold"> {{ user.email }} </span>
              </template>
              <template v-else> Email не указан</template>
            </div>
            <div class="profile-info-phone ">
             <span class="">{{ user.phone }}</span>
            </div>
            <b-button @click="showEditProfileModal = true" class="mt-3 profile__buttons"
              >Редактировать профиль
            </b-button>
          </div>
        </div>
        <!-- <div class="profile-box mb-3">
          <div class="fonto mb-3">Избранные</div>
          
        </div> -->
      </b-col>
      <b-col sm="6">
        <div class="profile-box profile__box align-items-start mb-3">
          <div class="fonto mb-3">Доступные скидки</div>
          <div v-if="usersDiscount" class="discount-box">{{ usersDiscount }}</div>
          <div v-else class="emptyy aaddrr">Доступных скидок нет</div>
        </div>
        <div class="profile-box profile__box align-items-start">
          <div class="fonto mb-3">Мои адреса </div>
          <div v-if="addresses && addresses.length" class="address__list w-100">
            <div v-for="address in addresses" :key="address.id" class="mb-3 d-flex justify-content-between aaddrr">
              <span class="font-italic mr-3"> {{ address.name }}</span>
              <span @click="openAddressDetails(address.id)" class="order-item__details">Посмотреть</span>
            </div>
            <b-button @click="showAddAddressModal" class="profile__buttons">Добавить адрес</b-button>
          </div>
          <div v-else class="empty aaddrr">
            <div class="emptyy">Сохраненных адресов нету, добавьте хотя бы один адрес</div> <br />
            <b-button @click="showAddAddressModal" class="profile__buttons">Добавить адрес</b-button>
          </div>
        </div>
      </b-col>
    </b-row>

    <b-modal style="padding-bottom: 90px;" v-model="showEditProfileModal" id="edit-profile-modal" centered title="Редактировать профиль" hide-footer>
      <b-overlay :show="isEditProfileModalLoading" spinner-variant="success" rounded="sm" opacity="0.3">
        <p>
          <!--          <b-form-group id="input-group-1" label="Номер телефона*" label-for="input-1">-->
          <!--            <b-form-input-->
          <!--              v-model.trim="phone"-->
          <!--              id="input-1"-->
          <!--              type="text"-->
          <!--              placeholder="+7 ("-->
          <!--              v-mask="'+7 (###) ### ## ##'"-->
          <!--              required-->
          <!--              :state="validatePhone"-->
          <!--            ></b-form-input>-->
          <!--            <b-form-invalid-feedback :state="validatePhone">-->
          <!--              Введите корректный номер телефона-->
          <!--            </b-form-invalid-feedback>-->
          <!--          </b-form-group>-->
          <b-form-group id="input-group-3" label="Полное имя*" label-for="input-3">
            <b-form-input
              v-model.trim="fullName"
              id="input-3"
              type="text"
              :state="validateFullName"
              placeholder="Введите полное имя*"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateFullName"> Введите корректные данные</b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="input-group-4" label="Email*" label-for="input-4">
            <b-form-input
              v-model.trim="email"
              id="input-4"
              type="email"
              :state="validateEmail"
              placeholder="Введите почту"
            ></b-form-input>
            <b-form-invalid-feedback :state="validateEmail"> Введите корректный email</b-form-invalid-feedback>
          </b-form-group>
        </p>
      </b-overlay>
      <b-button style="position: absolute; bottom: -25px;right: 15px;"  @click="updateProfileData()" class="mt-3" type="submit" variant="success">Изменить данные</b-button>

    </b-modal>
    <b-modal v-model="showCreateAddressModal" id="create-address-modal" centered title="Создать адрес" hide-footer>
      <div class="mb-3">
        <b-input
          v-model.trim="addressTitle"
          placeholder="Как сохранить адрес?"
          autocomplete="off"
          :state="validateAddressTitle"
        ></b-input>
        <b-form-invalid-feedback :state="validateAddressTitle"> Введите как сохранить адрес </b-form-invalid-feedback>
      </div>
      <div class="mb-3">
        <b-input
          v-model.trim="postIndex"
          placeholder="Почтовый индекс"
          type="text"
          v-mask="'######'"
          autocomplete="off"
          :state="validatePostCode"
        ></b-input>
        <b-form-invalid-feedback :state="validatePostCode"> Введите корректный почтовый код </b-form-invalid-feedback>
      </div>
      <div class="mb-3">
        <b-input
          v-model.trim="fullAddress"
          :state="validateFullAddress"
          placeholder="Введите полный адрес"
          type="text"
          autocomplete="off"
        ></b-input>
        <b-form-invalid-feedback :state="validateFullAddress"> Введите полный адрес </b-form-invalid-feedback>
      </div>
      <b-button @click="addAddress" variant="success">Создать</b-button>
    </b-modal>
    <b-modal v-model="showAddressDetails" id="address-details-modal" centered title="Детали адреса" hide-footer>
      <div class="mb-3">
        <b-form-group id="input-group-address-1" label="Как сохранить адрес:" label-for="input-group-address-1">
          <b-input v-model.trim="selectedAddress.title" autocomplete="off"></b-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group id="input-group-address-2" label="Почтовый индекс:" label-for="input-group-address-2">
          <b-input v-model.trim="selectedAddress.post_index" type="text" autocomplete="off"></b-input>
        </b-form-group>
      </div>
      <div class="mb-3">
        <b-form-group id="input-group-address-3" label="Полный адрес:" label-for="input-group-address-3">
          <b-input v-model.trim="selectedAddress.name" type="text" autocomplete="off"></b-input>
        </b-form-group>
      </div>
      <b-button @click="updateAddress" class="mr-4" variant="outline-success">Изменить</b-button>
      <b-button @click="deleteAddress" variant="outline-danger">Удалить</b-button>
    </b-modal>
  </section>
</template>

<script>
// import ProductCard from '@/components/Product/ProductCard'
// import VueSlickCarousel from 'vue-slick-carousel'
// import ArrowIcon from '@/components/ArrowIcon'
import Api from '@/services/api'
import { mask } from 'vue-the-mask'
// import { mask } from 'vue-the-mask'

export default {
  name: 'ProfilePage',
  directives: { mask },

  data: () => ({
    showEditProfileModal: false,
    showAddressDetails: false,
    isEditProfileModalLoading: false,
    isTouched: false,
    email: '',
    fullName: '',
    usersDiscount: 0,
    showCreateAddressModal: false,
    addressTitle: '',
    fullAddress: '',
    postIndex: '',
    isAddressTouched: null,
    selectedAddress: {},
  }),

  computed: {
    user() {
      return this.$store.state.user || {}
    },

    addresses() {
      return this.$store.state.addresses
    },

    validateFullName() {
      if (!this.isTouched) return null
      return this.fullName.length > 1
    },

    validateEmail() {
      if (!this.isTouched) return
      const reg = /\S+@\S+\.\S+/
      return reg.test(this.email)
    },

    validatePostCode() {
      if (!this.isAddressTouched) return null
      return this.postIndex.length === 6
    },

    validateAddressTitle() {
      if (!this.isAddressTouched) return null
      return this.addressTitle.length > 1
    },

    validateFullAddress() {
      if (!this.isAddressTouched) return null
      return this.fullAddress.length > 1
    },

    selectedCity() {
      return this.$store.state.selectedCity
    },
  },

  async mounted() {
    const { discount } = await this.wrapAndHandleRequest(Api.getDiscounts)
    const { addresses } = await this.wrapAndHandleRequest(Api.fetchAddressList, false)
    this.$store.commit('SET_ADDRESSES', addresses)
    this.usersDiscount = discount
    this.fullName = this.user.fullname
    this.email = this.user.email
  },

  methods: {
    logout() {
      this.$store.commit('LOGOUT')
      this.$router.push({ name: 'auth.login' })
    },

    async updateAddress() {
      const title = this.selectedAddress.title
      const postIndex = this.selectedAddress.post_index
      const name = this.selectedAddress.name

      const initialSelectedAddress = this.addresses.find((el) => el.id === this.selectedAddress.id)

      if (
        title === initialSelectedAddress.title &&
        postIndex === initialSelectedAddress.post_index &&
        name === initialSelectedAddress.name
      ) {
        return (this.showAddressDetails = false)
      }

      const data = {
        id: this.selectedAddress.id,
        status: 1,
        cityId: initialSelectedAddress.city_id,
        postIndex,
        name,
        title,
      }
      const { address } = await this.wrapAndHandleRequest(() => Api.updateAddress(data), true)
      this.$store.commit('UPDATE_ADDRESS', address)
      this.makeToast('Изменение адреса', 'Адрес был успешно изменен', 'success', false)
      this.showAddressDetails = false
    },

    async deleteAddress() {
      if (this.selectedAddress.id) {
        await this.wrapAndHandleRequest(
          () =>
            Api.deleteAddress({
              id: this.selectedAddress.id,
            }),
          true,
        )
        this.$store.commit('DELETE_ADDRESS', this.selectedAddress.id)
        this.makeToast('Удаление адреса', 'Адрес был успешно удален', 'success', false)
        this.showAddressDetails = false
      }
    },

    openAddressDetails(id) {
      const selectedAddress = this.addresses.find((el) => el.id === id)
      this.selectedAddress = { ...selectedAddress }
      this.showAddressDetails = true
    },

    showAddAddressModal() {
      this.showCreateAddressModal = true
    },

    validateAddressData() {
      return this.validatePostCode && this.validateAddressTitle && this.validateFullAddress
    },

    async addAddress() {
      this.isAddressTouched = true
      if (!this.validateAddressData()) return
      if (!this.selectedCity) {
        return this.makeToast(
          'Адрес',
          'Перед тем как добавить адрес доставки, выберите город в правом верхнем углу',
          'warning',
        )
      }
      const data = {
        name: this.fullAddress,
        title: this.addressTitle,
        cityId: this.selectedCity.id,
        postIndex: this.postIndex,
        status: 1,
      }
      const { address } = await this.wrapAndHandleRequest(() => Api.postAddress(data))
      this.addressTitle = ''
      this.fullAddress = ''
      this.postIndex = ''
      this.$store.commit('ADD_ADDRESS', address)
      this.isAddressTouched = null
      this.showCreateAddressModal = false
    },

    async updateProfileData() {
      this.isTouched = true
      if (!this.validateData()) return
      if (this.checkIsFilledDataSameAsSet()) {
        this.showEditProfileModal = false
      }
      try {
        this.isEditProfileModalLoading = true
        const { user } = await this.wrapAndHandleRequest(() =>
          Api.updateProfile({
            fullname: this.fullName,
            email: this.email,
            // phone: this.phone.replace(/[+() ]/g, ''),
          }),
        )
        const userData = this.user
        userData.email = user.email
        userData.fullname = user.fullname
        this.$store.commit('SET_USER', userData)
        // this.email = user.email
        // this.fullName = user.fullname
      } finally {
        this.isEditProfileModalLoading = false
        this.showEditProfileModal = false
      }
    },

    validateData() {
      // return this.validatePhone && this.validateFullName && this.validateEmail
      return this.validateFullName && this.validateEmail
    },

    checkIsFilledDataSameAsSet() {
      // return this.phone === this.user.phone && this.fullName === this.user.fullname && this.email === this.user.email
      return this.fullName === this.user.fullname && this.email === this.user.email
    },
  },
}
</script>

<style>
.modal-content{
  padding-bottom: 40px!important;
}
</style>