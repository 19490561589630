<template>
  <div v-if="slides.length" class="second-top-banners">
    <div v-for="slide in slides" :key="slide.id" class="second-top-banner__item-wrapper">
      <div
        class="second-top-banner__item"
        @click="redirect(slide.url)"
        :style="{ backgroundImage: `url(${slide.path})` }"
      ></div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'SecondTopBanners',
  data: () => ({
    slides: [],
  }),

  async mounted() {
    const { banners } = await this.wrapAndHandleRequest(() => Api.getTopSlides('zone=4'), false)
    this.slides = banners
  },

  methods: {
    redirect(url) {
      if (!url) return
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', url)
      link.click()
    },
  },
}
</script>

<style lang="scss">
.second-top-banners {
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  //gap: 15px;
}

.second-top-banner {
  &__item {
    height: auto!important;
    background-size: cover!important; /* Adjust to fit the div */
    background-repeat: no-repeat!important;
    background-position: center!important;
    width: 100%!important; /* Set the desired width */
    padding-top: 27.25%!important; /* Aspect ratio: 16:9 (height / width * 100) */
    position: relative!important; /* Required to keep padding-based height */

    &-wrapper {
      width: 33%;
      border-radius: 10px;
      padding: 8px;
    }
  }
}

@media (max-width: 766.99px) {




  .second-top-banner {
    &__item {
      margin-bottom: 10px;
      &-wrapper {
        padding: 0px!important;
      }
    }
  }
  .second-top-banners {
    flex-direction: column;
  }

  .second-top-banner {
    &__item-wrapper {
      width: 100%;
    }
  }
}
//
//@media (max-width: 480.99px) {
//  .top-banner {
//    &__item {
//      width: 50%;
//      padding: 4px;
//    }
//  }
//}
</style>
