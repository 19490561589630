<template>
  <div v-if="slides.length" class="top-banners">
    <div v-for="slide in slides" :key="slide.id" class="top-banner__item-wrapper">
      <div
        class="top-banner__item22"
        @click="redirect(slide.url)"
        :style="{ backgroundImage: `url(${slide.path})` }"
      ></div>
    </div>
  </div>
</template>

<script>
import Api from '@/services/api'

export default {
  name: 'TopBanners',
  data: () => ({
    slides: [],
  }),

  async mounted() {
    const { banners } = await this.wrapAndHandleRequest(() => Api.getTopSlides('zone=3'), false)
    this.slides = banners
  },

  methods: {
    redirect(url) {
      if (!url) return
      const link = document.querySelector('.invisibleLink')
      link.setAttribute('href', url)
      link.click()
    },
  },
}
</script>

<style lang="scss">
.top-banners {
  display: flex;
  //align-items: center;
  flex-wrap: wrap;
  //gap: 15px;
}

.top-banner {
  &__item {
    height: auto!important;
    background-size: cover!important; /* Adjust to fit the div */
    background-repeat: no-repeat!important;
    background-position: center!important;
    width: 100%!important; /* Set the desired width */
    padding-top: 31.25%!important; /* Aspect ratio: 16:9 (height / width * 100) */
    position: relative!important; /* Required to keep padding-based height */

    &-wrapper {
      width: 50%;
      padding: 8px;
      border-radius: 10px;
    }
  }

  &__item22 {
    height: auto!important;
    background-size: cover!important; /* Adjust to fit the div */
    background-repeat: no-repeat!important;
    background-position: center!important;
    width: 100%!important; /* Set the desired width */
    padding-top: 31.25%!important; /* Aspect ratio: 16:9 (height / width * 100) */
    position: relative!important; /* Required to keep padding-based height */
  }
    
}

@media (max-width: 766.99px) {

  .top-banner {
  &__item22 {

    &-wrapper {
      width: 100%!important;
      padding: 0px;
      border-radius: 10px;
    }
    
  .top-banner {
    &__item-wrapper {
      width: 100%;
    }
  }
}
  }}
@media (max-width: 480.99px) {
  .top-banner {
    &__item-wrapper {
      width: 100%;
      padding: 0px;
      margin-bottom: 8px;
    }
  }
}
</style>
